$(document).ready(function () {
    $('body').on('submit', '.js-auth-form', function (e) {
        e.preventDefault();

        let form_action = $(this).attr('action');
        let form_backurl = $(this).find('input[name="backurl"]').val();

        $.ajax({
            type: "POST",
            url: '/ajax/auth.php',
            data: $(this).serialize(),
            timeout: 3000,
            error: function (request, error) {
                if (error == "timeout") {
                    alert('timeout');
                } else {
                    alert('Error! Please try again!');
                }
            },
            success: function (data) {
                $('#signinModal .modal-body').html(data);

                $('#signinModal form').attr('action', form_action);
                $('#signinModal input[name=backurl]').val(form_backurl);
            }
        });

        return false;
    })
});