export default class CleanForm {
    constructor() {
        this.form = document.querySelector('.js-cleaning-form');
        if (this.form) {
            this.elements = this.form.querySelectorAll('.js-cleaning-form-init');
        }


        if (this.elements) {
            this.elements.forEach(el => {
                el.onchange = this.handleChange.bind(this);
            })
        }
    }

    handleChange(event) {
        let elements = this.form.querySelectorAll('input[type="text"]:not([name="Register[UF_LEGAL_TYPE]"])');
        if (elements) {
            elements.forEach(el => {
                el.value = "";
            });
        }
    }
}