// import $ from 'jquery';
// import * as Cookies from "es-cookie";

// window.$ = window.jQuery = $;
// window.Cookies = Cookies;
// import 'lightbox2';
// import 'popper.js';
// import 'bootstrap';


import './include/init-maskedinput';
import './include/init-range-sliders';
import './include/_jquery-code';

import DadataApi from './classes/DadataApi';
import CleanForm from './classes/CleanForm';

new DadataApi();
new CleanForm();