export default class DadataApi {
    /**
     * @return {boolean}
     */
    constructor() {
        this.container = document.querySelector('[data-ddata]');

        if (!this.container) return false;

        this.initElement = this.container.querySelector('[data-ddata-init]');


        if (!this.initElement) return false;

        this.init();
    }

    init() {
        if (this.initElement) this.initElement.onchange = this.handleChangeStartInput.bind(this);
    }

    handleChangeStartInput(event) {
        let element = event.target;
        this.query(element.value);
    }

    query(query) {
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
        let token = "21ebc5ed8ce5d22637d7c721b2a20621bbe00646";
        // let query = "7707083893";

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }

        fetch(url, options)
            .then(response => response.text())
            .then(result => {
                let json_result = JSON.parse(result);
                let sugg = json_result.suggestions;
                if (sugg.length === 1) {
                    let current_sugg = sugg[0].data;

                    for (let key in current_sugg) {
                        let el_k = this.container.querySelector(`[data-ddata-key-${key}]`);
                        if (el_k) {
                            el_k.value = current_sugg[key];
                        }
                    }

                    try {
                        this.container.querySelector(`[data-ddata-key-legal-address]`).value = current_sugg.address.unrestricted_value;
                    } catch (e) {

                    }
                }
            })
            .catch(error => console.log("error", error));
    }
}

