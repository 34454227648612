import Inputmask from "maskedinput";

document.addEventListener('DOMContentLoaded', () => {
    let russsianPhone = document.querySelectorAll(".js-mask-ru");

    if (russsianPhone) {
        russsianPhone.forEach(el => {
            let im = new Inputmask("+7 (999) 999 99-99", {placeholder: "+7 (___) ___ __ __"});
            im.mask(el);
        });
    }


    let timeMask = document.querySelectorAll(".js-time-mask");
    if (timeMask) {
        timeMask.forEach(el => {
            let im = new Inputmask("99:99:99");
            im.mask(el);
        });
    }

    let innMask = document.querySelectorAll(".js-inn-mask");
    if (innMask) {
        innMask.forEach(el => {
            let im = new Inputmask("9{10,12}");
            im.mask(el);
        });
    }

    let orgnMask = document.querySelectorAll(".js-ogrn-mask");
    if (orgnMask) {
        orgnMask.forEach(el => {
            let im = new Inputmask("9{13,15}");
            im.mask(el);
        });
    }

    let corAcc = document.querySelectorAll(".js-corr-acc-mask");
    if (corAcc) {
        corAcc.forEach(el => {
            let im = new Inputmask("9{20}");
            im.mask(el);
        });
    }

    let bikMask = document.querySelectorAll(".js-bik-kpp-mask");
    if (bikMask) {
        bikMask.forEach(el => {
            let im = new Inputmask("9{9}");
            im.mask(el);
        });
    }
});
